import SideDrawer from "components/SideDrawer";
import { useSideDrawer } from "hooks/useSideDrawer";
import React, { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useExaminees } from "store/entities/examinees/useExaminees";
import { IExamEntity } from "store/entities/exams/exam.model";
import { useExams } from "store/entities/exams/useExams";
import { getExamModel } from "./exams.tablemodel";
import ExamUpdateForm from "./ExamUpdateForm";

function ExamList() {
	const { exams, fetchAllExams, status } = useExams();
	const {
		examinees,
		fetchAllExaminees,
		status: examineeStatus,
	} = useExaminees();

	const [selected, setSelected] = useState<IExamEntity | null>(null);
	const { visible, drawerActions } = useSideDrawer();

	useEffect(() => {
		if (!exams.length) fetchAllExams();
		if (!examinees.length) fetchAllExaminees();
		// eslint-disable-next-line
	}, []);

	return (
		<div className="table">
			<DataTable
				pointerOnHover
				highlightOnHover
				data={exams}
				columns={getExamModel(examinees)}
				progressPending={
					status === "loading" || examineeStatus === "loading"
				}
				onRowClicked={(e) => {
					setSelected(e);
					drawerActions.open();
				}}
			/>

			<SideDrawer
				visible={visible}
				onClose={() => {
					setSelected(null);
					drawerActions.close();
				}}
				title="Exam Details"
			>
				<ExamUpdateForm
					item={selected}
					onFinish={drawerActions.close}
				/>
			</SideDrawer>
		</div>
	);
}

export default ExamList;
