import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IExam, IExamEntity } from "./exam.model";

export interface IExamRedux extends IExam {
	status: "idle" | "loading" | "loaded" | "failed";
	error?: string;
}

const initState: IExamRedux = {
	exams: [],
	status: "idle",
	error: "",
};

// define actions here
const addExam: CaseReducer<IExamRedux, PayloadAction<IExamEntity[]>> = (
	state,
	action
) => {
	state.exams = action.payload;
	state.error = "";
	state.status = "loaded";
};
const addOneExam: CaseReducer<IExamRedux, PayloadAction<IExamEntity>> = (
	state,
	action
) => {
	state.exams = [...state.exams, action.payload];
};

const deleteExam: CaseReducer<IExamRedux, PayloadAction<{ id: string }>> = (
	state,
	action
) => {
	const list = state.exams.filter((item) => item._id !== action.payload.id);
	state.exams = list;
};
const updateExam: CaseReducer<
	IExamRedux,
	PayloadAction<{ id: string; payload: IExamEntity }>
> = (state, action) => {
	const list = state.exams.filter((item) =>
		item._id !== action.payload.id ? item : action.payload.payload
	);

	state.exams = list;
};

const activateExam: CaseReducer<IExamRedux, PayloadAction<{ id: string }>> = (
	state,
	action
) => {
	const exam = state.exams.find((item) => item._id === action.payload.id);
	if (exam) exam.isActive = true;
};
const deActivateExam: CaseReducer<IExamRedux, PayloadAction<{ id: string }>> = (
	state,
	action
) => {
	const exam = state.exams.find((item) => item._id === action.payload.id);
	if (exam) exam.isActive = false;
};

const empty: CaseReducer<IExamRedux> = (state) => {
	state.exams = [];
};
const loading: CaseReducer<IExamRedux> = (state) => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<IExamRedux, PayloadAction<string>> = (
	state,
	action
) => {
	state.status = "failed";
	state.error = action.payload;
};

// action definitions ends

export const examSlice = createSlice({
	name: "exam",
	initialState: initState,
	reducers: {
		addExam,
		addOneExam,
		deleteExam,
		updateExam,
		activateExam,
		deActivateExam,
		loading,
		failed,
		empty,
	},
});

export const examActions = examSlice.actions;
