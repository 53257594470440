import { ArrowLeftOutlined } from "@ant-design/icons";
import { Layout, Form, Button, Input, Space } from "antd";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "store/entities/auth/useAuth";

function ForgotPasswordForm() {
	const { passwordResetRequest } = useAuth();
	const [requesting, setRequesting] = useState<boolean>(false);
	const history = useHistory();

	const _submitRequest = (values: any) => {
		setRequesting(true);
		passwordResetRequest(values["email"])
			.then(() => {
				history.push("/");
			})
			.finally(() => {
				setRequesting(false);
			});
	};

	return (
		<div className="authoritive__form">
			<Layout>
				<Layout.Header>
					<h2>Forgot Password?</h2>
				</Layout.Header>
				<Layout.Content>
					<h3>Request password reset</h3>
					<Form layout="vertical" onFinish={_submitRequest}>
						<Form.Item
							name="email"
							label="Email"
							required
							rules={[
								{
									required: true,
									message: "Email is required",
								},
							]}
						>
							<Input />
						</Form.Item>

						<Button
							disabled={requesting}
							loading={requesting}
							type="primary"
							htmlType="submit"
						>
							Request
						</Button>
					</Form>
				</Layout.Content>
				<Space style={{ marginTop: 20 }}>
					<Link to="/">
						<ArrowLeftOutlined /> Back to Log In
					</Link>
				</Space>
			</Layout>
		</div>
	);
}

export default ForgotPasswordForm;
