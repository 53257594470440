import React, { useRef } from "react";
import { Input } from "antd";
import styled from "styled-components";
import { FileImageOutlined } from "@ant-design/icons";

interface ImageUploadFieldProps {
	onChange?: (file: File) => void;
	file?: File | null;
}

const StyledImageUploadField = styled.div`
	cursor: pointer;

	width: 100%;
	padding: 20px;

	font-family: var(--ff);

	border: 2px dashed #e7e7e7;
	background-color: #f9f9f9;

	input {
		display: none;
	}

	p {
		margin: 0;
	}

	.hint {
		color: #ababab;
		text-align: center;
	}
`;

function ImageUploadField({ file, onChange }: ImageUploadFieldProps) {
	const fileInputRef = useRef<any>(null);

	const _onChange = (e: any) => {
		const f: File = e.target.files[0];
		onChange && onChange(f);
	};

	const triggerInput = () => {
		fileInputRef?.current?.input?.click();
	};

	const parseFileSize = (size: number) => {
		let suffix = "B";
		if (size < 1000) return `${size} ${suffix}`;
		let _size = size % 1000;
		let pref = size / 1000;
		suffix = "KB";
		if (_size < 1000) return `${pref.toFixed(1)} ${suffix}`;
		pref = _size / 1000;
		_size = size % 1000;
		suffix = "MB";
		if (_size < 1000) return `${pref.toFixed(1)} ${suffix}`;
		pref = _size / 1000;
		_size = size % 1000;
		suffix = "GB";
		return `${pref.toFixed(1)} ${suffix}`;
	};

	return (
		<div className="image-upload-field">
			<StyledImageUploadField onClick={triggerInput}>
				<Input
					ref={fileInputRef}
					style={{ display: "none" }}
					onChange={_onChange}
					accept="image/png,image/jpeg"
					type="file"
				/>
				<p className="hint">
					{file ? (
						<FileImageOutlined
							style={{ transform: "scale(1.5)" }}
						/>
					) : (
						"Upload image file (if needed)."
					)}
				</p>
			</StyledImageUploadField>
			<div className="fileList">
				{file ? (
					<div className="fileList-item">
						<small>{file.name}</small>
						<small>{parseFileSize(file.size)}</small>
					</div>
				) : null}
			</div>
		</div>
	);
}

export default ImageUploadField;
