import { Tag } from "antd";
import type { TableColumn } from "react-data-table-component";
import { IExamineeUser } from "store/entities/examinees/examinees.model";
import { IExamEntity } from "store/entities/exams/exam.model";
import { Loading3QuartersOutlined } from "@ant-design/icons";

export const getExamModel = (
	examinees: IExamineeUser[]
): TableColumn<IExamEntity>[] => {
	return [
		{
			name: "Shift",
			selector: (item) => item.title,
		},
		{
			name: "Candidates",
			cell: (exam) => (
				<span>
					{
						examinees.filter(
							(item) => item.examTag._id === exam._id
						).length
					}
				</span>
			),
		},
		{
			name: "Session",
			cell: (exam) => (
				<>
					<Tag
						icon={
							exam.isActive ? (
								<Loading3QuartersOutlined spin />
							) : null
						}
						color={exam.isActive ? "green" : "default"}
					>
						{exam.isActive ? "Active" : "Inactive"}
					</Tag>
				</>
			),
		},
	];
};
