import AdminPageHeader from "components/AdminPageHeader";
import React from "react";
import ScoreList from "./ScoreList";

import "./scores.styles.scss";

function ScoreView() {
	return (
		<div className="score_view">
			<AdminPageHeader title="Scores" />
			<ScoreList />
		</div>
	);
}

export default ScoreView;
