import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { ILog } from "store/entities/logs/log.model";
import { useLogs } from "store/entities/logs/useLogs";
import { getLogModel } from "./logs.tablemodel";
import LogSingleUser from "./LogSingleUser";

function ScoreList() {
	const [logData, setLogData] = useState<{
		status: "idle" | "loading" | "loaded" | "error";
		data: ILog[];
		filtered: ILog[];
	}>({ data: [], status: "loading", filtered: [] });
	const { fetchAllLog } = useLogs();

	useEffect(() => {
		fetchAllLog()
			.then((data) => {
				if (data)
					setLogData({
						data,
						status: "loaded",
						filtered: [],
					});
			})
			.catch((ex) => {
				setLogData({
					data: [],
					status: "error",
					filtered: [],
				});
			});
		// eslint-disable-next-line
	}, []);

	const [searchText, setSearchText] = useState<string>("");

	useEffect(() => {
		if (!searchText.length) setLogData({ ...logData, filtered: [] });

		const _searchReg = new RegExp(searchText.toLowerCase(), "i");

		const _searched = logData.data.filter((item) => {
			const num = item.user?.symbolNumber.toLowerCase();
			return !!num && num.search(_searchReg) > -1;
		});

		setLogData({
			...logData,
			filtered: _searched,
		});

		// eslint-disable-next-line
	}, [logData.data, searchText]);

	return (
		<div className="table">
			<div className="header" style={{ marginBottom: 20 }}>
				<Form layout="inline">
					<Form.Item label="Search">
						<Input
							allowClear
							value={searchText}
							onChange={(e) => setSearchText(e.target.value)}
							placeholder="Symbol Number"
						/>
					</Form.Item>
					{searchText.length && !logData.filtered.length ? (
						<div
							className="info"
							style={{
								paddingTop: 5,
							}}
						>
							<small>Not found</small>
						</div>
					) : null}
				</Form>
			</div>
			<DataTable
				className="scoreTable"
				pointerOnHover
				highlightOnHover
				data={
					searchText.length || logData.filtered.length
						? logData.filtered
						: logData.data
				}
				columns={getLogModel()}
				progressPending={logData.status === "loading"}
				expandableRows
				expandOnRowClicked
				expandableRowsComponent={LogSingleUser}
			/>
		</div>
	);
}

export default ScoreList;
