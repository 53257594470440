import AdminPageHeader from "components/AdminPageHeader";
import SideDrawer from "components/SideDrawer";
import { useSideDrawer } from "hooks/useSideDrawer";
import React from "react";
import ExamineeCreateForm from "./ExamineeCreateForm";
import ExamineesList from "./ExamineesList";

function ExamineeView() {
	const { visible, drawerActions } = useSideDrawer();
	return (
		<div className="examinee_view">
			<AdminPageHeader title="Candidates" action={drawerActions.open} />
			<ExamineesList />
			<SideDrawer
				title="Add Candidate(s)"
				onClose={drawerActions.close}
				visible={visible}
			>
				<ExamineeCreateForm onFinish={drawerActions.close} />
			</SideDrawer>
		</div>
	);
}

export default ExamineeView;
