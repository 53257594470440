import React from "react";
import { Rule } from "antd/lib/form";
import { useAuth } from "store/entities/auth/useAuth";
import { Button, Form, Input, Space } from "antd";
import { Link } from "react-router-dom";

const _required: Rule = {
	required: true,
	message: "This field is required.",
};

function LoginForm() {
	const { login } = useAuth();

	const attemptLogin = (values: any) => {
		const { email = "", password = "" } = values;

		login(email, password);
	};

	return (
		<div className="login__form">
			<h3>Admin - Log In</h3>
			<Form
				onFinish={attemptLogin}
				name="examnee__login"
				layout="vertical"
			>
				<Form.Item
					rules={[_required]}
					name="email"
					label="Email"
					required
				>
					<Input />
				</Form.Item>
				<Form.Item
					rules={[_required]}
					name="password"
					label="Password"
					required
				>
					<Input.Password />
				</Form.Item>

				<Space
					direction="vertical"
					align="end"
					style={{ marginBottom: 20 }}
				>
					<Link to="/forgot-password">Forgot password?</Link>
				</Space>

				<Button htmlType="submit" block type="primary">
					Log in
				</Button>
			</Form>
		</div>
	);
}

export default LoginForm;
