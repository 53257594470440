import AdminPageHeader from "components/AdminPageHeader";
import SideDrawer from "components/SideDrawer";
import { useSideDrawer } from "hooks/useSideDrawer";
import React from "react";
import QuestionCreateForm from "./QuestionCreateForms";
import QuestionLists from "./QuestionLists";

function QuestionView() {
	const { visible, drawerActions } = useSideDrawer();

	return (
		<div>
			<AdminPageHeader title="Questions" action={drawerActions.open} />
			<QuestionLists />

			<SideDrawer
				title="Add a question"
				onClose={drawerActions.close}
				visible={visible}
			>
				<QuestionCreateForm onFinish={drawerActions.close} />
			</SideDrawer>
		</div>
	);
}

export default QuestionView;
