import { useState } from "react";

export const useSideDrawer = () => {
	const [drawer, setDrawer] = useState<boolean>(false);

	const open = () => setDrawer(true);
	const close = () => setDrawer(false);

	return {
		visible: drawer,
		drawerActions: {
			open,
			close,
		},
	};
};
