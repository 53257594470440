import React, { FC } from "react";
import { Drawer } from "antd";

interface SideDrawerProps {
	title: string;
	visible: boolean;
	onClose: () => void;
}

const SideDrawer: FC<SideDrawerProps> = ({
	title,
	visible,
	onClose,
	children,
	...otherProps
}) => {
	return (
		<Drawer
			{...otherProps}
			title={title}
			visible={visible}
			onClose={onClose}
			width={540}
			closable
			destroyOnClose
		>
			{children}
		</Drawer>
	);
};

export default SideDrawer;
