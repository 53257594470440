import { message } from "antd";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { IExamCreateRequest } from "./exam.model";
import { examActions } from "./exam.redux";
import {
	onAllExamGet,
	onExamActivate,
	onExamCreate,
	onExamDeactivate,
	onExamDelete,
} from "./exam.service";

export const useExams = () => {
	const examStates = useAppSelector((state) => state.exams);
	const dispatch = useAppDispatch();

	const fetchAllExams = async () => {
		try {
			dispatch(examActions.loading());
			const resp = await onAllExamGet();
			dispatch(examActions.addExam(resp));
		} catch (ex) {
			dispatch(
				examActions.failed(
					ex.message || "Could not load exams at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					"Failed to load exam data. Refresh."
			);
		}
	};

	const addExam = async (payload: IExamCreateRequest) => {
		try {
			await onExamCreate(payload);
			await fetchAllExams();
		} catch (ex) {
			dispatch(
				examActions.failed(
					ex.message || "Could not add exam at the moment."
				)
			);
			message.error(
				ex.response?.data?.message || "Failed to add exam. Try again."
			);
		}
	};

	const deleteExam = async (examId: string) => {
		try {
			await onExamDelete(examId);
			dispatch(examActions.deleteExam({ id: examId }));
		} catch (ex) {
			dispatch(
				examActions.failed(
					ex.message || "Could not delete exam at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					"Failed to delete exam. Try again."
			);
		}
	};

	const activateExam = async (examId: string) => {
		try {
			await onExamActivate(examId);
			dispatch(examActions.activateExam({ id: examId }));
		} catch (ex) {
			dispatch(
				examActions.failed(
					ex.message || "Could not activate exam at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					"Failed to activate exam. Try again."
			);
		}
	};
	const deActivateExam = async (examId: string) => {
		try {
			await onExamDeactivate(examId);
			dispatch(examActions.deActivateExam({ id: examId }));
		} catch (ex) {
			dispatch(
				examActions.failed(
					ex.message || "Could not deactivate exam at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					"Failed to deactivate exam. Try again."
			);
		}
	};

	useEffect(() => {
		if (!examStates.exams.length) fetchAllExams();
		// eslint-disable-next-line
	}, []);

	return {
		...examStates,
		fetchAllExams,
		addExam,
		deleteExam,
		deActivateExam,
		activateExam,
	};
};
