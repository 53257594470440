import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IExamineeUser } from "./examinees.model";

export interface IExamineeRedux {
	examinees: IExamineeUser[];
	status: "idle" | "loading" | "loaded" | "failed";
	error?: string;
}

const initState: IExamineeRedux = {
	examinees: [],
	status: "idle",
	error: "",
};

// define actions here
const addExaminees: CaseReducer<
	IExamineeRedux,
	PayloadAction<IExamineeUser[]>
> = (state, action) => {
	state.examinees = action.payload;
	state.error = "";
	state.status = "loaded";
};
const addOneExaminee: CaseReducer<
	IExamineeRedux,
	PayloadAction<IExamineeUser>
> = (state, action) => {
	state.examinees = [...state.examinees, action.payload];
};

const deleteExaminee: CaseReducer<
	IExamineeRedux,
	PayloadAction<{ id: string }>
> = (state, action) => {
	const list = state.examinees.filter(
		(item) => item._id !== action.payload.id
	);
	state.examinees = list;
};
const updateExaminee: CaseReducer<
	IExamineeRedux,
	PayloadAction<{ id: string; payload: IExamineeUser }>
> = (state, action) => {
	const list = state.examinees.filter((item) =>
		item._id !== action.payload.id ? item : action.payload.payload
	);

	state.examinees = list;
};
const empty: CaseReducer<IExamineeRedux> = (state) => {
	state.examinees = [];
};
const loading: CaseReducer<IExamineeRedux> = (state) => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<IExamineeRedux, PayloadAction<string>> = (
	state,
	action
) => {
	state.status = "failed";
	state.error = action.payload;
};
// action definition ends

export const examineeSlice = createSlice({
	name: "auth",
	initialState: initState,
	reducers: {
		addExaminees,
		addOneExaminee,
		empty,
		deleteExaminee,
		updateExaminee,
		loading,
		failed,
	},
});

export const examineeActions = examineeSlice.actions;
