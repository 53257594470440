import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import "./authoritive.styles.scss";

import ChangePasswordForm from "./widget/ChangePassword";
import ForgotPasswordForm from "./widget/ForgotPassword";

export default function AuthoritiveView() {
	const { path } = useRouteMatch();

	return (
		<div className="authoritive__view">
			<Switch>
				<Route
					path={path + "/change/:userId/:token/"}
					exact
					component={ChangePasswordForm}
				/>
				<Route path={path + "/"} exact component={ForgotPasswordForm} />
				<Redirect from="/**" to={path + "/"} />
			</Switch>
		</div>
	);
}
