import React from "react";
import { Rule } from "antd/lib/form";
import { Button, Form, Input, message, Space } from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import { useAuth } from "store/entities/auth/useAuth";
import { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";

const _required: Rule = {
	required: true,
	message: "This field is required.",
};

function ChangePasswordForm() {
	const location = useRouteMatch();
	const { passwordReset } = useAuth();

	const [requesting, setRequesting] = useState<boolean>(false);

	const attemptPasswordChange = (values: any) => {
		const { password = "", confirmPassword = "" } = values;

		if (password === confirmPassword) {
			const { userId = "", token = "" }: any = location.params;
			setRequesting(true);
			passwordReset(userId, token, password)
				.then(() => {
					message.success("Password changed successfully.");
				})
				.finally(() => {
					setRequesting(false);
				});
		} else {
			message.error(
				"Password do not match. Confirm your password first."
			);
		}
	};

	return (
		<div className="authoritive__form">
			<div className="form-wrapper">
				<h3>Admin - Change Password</h3>
				<Form
					onFinish={attemptPasswordChange}
					name="authoritive__password__change"
					layout="vertical"
				>
					<Form.Item
						rules={[_required]}
						name="password"
						label="Password"
						required
					>
						<Input.Password />
					</Form.Item>
					<Form.Item
						rules={[_required]}
						name="confirmPassword"
						label="Confirm Password"
						required
					>
						<Input.Password />
					</Form.Item>

					<Button
						loading={requesting}
						htmlType="submit"
						block
						type="primary"
					>
						Change
					</Button>
				</Form>

				<Space style={{ marginTop: 20 }}>
					<Link to="/">
						<ArrowLeftOutlined /> Back to Log In
					</Link>
				</Space>
			</div>
		</div>
	);
}

export default ChangePasswordForm;
