import axios from "axios";
import { api_configs } from "configs/api_configs";

const rAxios = axios.create({
	baseURL: api_configs.baseUrl || "",
});

rAxios.interceptors.request.use((cfg) => {
	const token = localStorage.getItem("token");
	if (!token) return cfg;
	cfg.headers = {
		...cfg.headers,
		Authorization: `Bearer ${token}`,
	};
	return cfg;
});

rAxios.interceptors.response.use((payload) => payload.data);

export default rAxios;
