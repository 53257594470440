import jwtDecode from "jwt-decode";
import rAxios from "utils/rAxios";
import { IExamineeLoginResponse, IAdminUser } from "./auth.model";

export const onExamineeLogin = (
	email: string,
	password: string
): Promise<IExamineeLoginResponse> =>
	rAxios.post("/admin/login", {
		email,
		password,
	});

export const decodeToken = (token: string): IAdminUser => jwtDecode(token);

export const onPasswordResetRequest = (email: string) =>
	rAxios.post("/admin/password-reset", {
		email,
	});
export const onPasswordChange = (id: string, token: string, password: string) =>
	rAxios.post(`/admin/password-reset/${id}/${token}`, {
		password,
	});
