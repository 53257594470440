import React from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useQuestions } from "store/entities/questions/useQuestions";
import { getLongQuestionModel } from "./longquestions.tablemodel";

function LongQuestionTable() {
	const { long, fetchAllLongQuestions, status } = useQuestions();

	useEffect(() => {
		if (!long.length) fetchAllLongQuestions();
		// eslint-disable-next-line
	}, []);

	return (
		<DataTable
			pointerOnHover
			highlightOnHover
			data={long}
			columns={getLongQuestionModel()}
			progressPending={status === "loading"}
		/>
	);
}

export default LongQuestionTable;
