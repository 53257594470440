import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useQuestions } from "store/entities/questions/useQuestions";
import { getMCQModel } from "./mcq.tablemodel";

function MCQTable() {
	const { mcqs, fetchAllMCQs, status } = useQuestions();

	useEffect(() => {
		if (!mcqs.length) fetchAllMCQs();
		// eslint-disable-next-line
	}, []);
	return (
		<div>
			<DataTable
				pointerOnHover
				highlightOnHover
				data={mcqs}
				columns={getMCQModel()}
				progressPending={status === "loading"}
			/>
		</div>
	);
}

export default MCQTable;
