import { TableColumn } from "react-data-table-component";
import { IQuestionMCQEntity } from "store/entities/questions/question.model";

export const getMCQModel = (): TableColumn<IQuestionMCQEntity>[] => [
	{
		name: "Question",
		cell: (item) => (
			<span>
				{item.title.length <= 50
					? item.title
					: item.title.substr(0, 50) + "..."}
			</span>
		),
	},
	{
		name: "Tag",
		selector: (item) => item.tag,
	},
	{
		name: "Supplement",
		cell: (item) => (
			<span>
				<img
					className="thumbnail"
					src={item.image}
					alt="thumbnail mcq supplement"
				/>
			</span>
		),
	},
	{
		name: "Options",
		cell: (item) => <span>4</span>,
	},
];
