import { message } from "antd";
import {
	onLongAnswerGet,
	onScoreAllGet,
	onScoreOneUserGet,
} from "./score.service";

export const useScore = () => {
	const fetchAllOfExam = async (examId: string) => {
		try {
			return await onScoreAllGet(examId);
		} catch (ex) {
			message.error(
				ex.response?.data?.message ||
					"Could not fetch scores at the moment. Reload."
			);
			throw ex;
		}
	};
	const fetchScoreOfOneUser = async (userId: string, examId: string) => {
		try {
			return await onScoreOneUserGet(examId, userId);
		} catch (ex) {
			message.error(
				ex.response?.data?.message ||
					"Could not fetch scores at the moment. Reload."
			);
			throw ex;
		}
	};
	const fetchLongAnswerReport = async (userId: string) => {
		try {
			return await onLongAnswerGet(userId);
		} catch (ex) {
			message.error(
				ex.response?.data?.message ||
					"Could not fetch long answer at the moment. Retry!!!"
			);
			throw ex;
		}
	};
	return { fetchAllOfExam, fetchScoreOfOneUser, fetchLongAnswerReport };
};
