import React from "react";
import LoginForm from "./widgets/LoginForm";

import "./login.styles.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function LoginView() {
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (location.pathname !== "/") history.replace("/");
		// eslint-disable-next-line
	}, [location.pathname]);

	return (
		<div className="login__view">
			<LoginForm />
		</div>
	);
}
