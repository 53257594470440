import rAxios from "utils/rAxios";
import { GenericMessageResponse } from "../examinees/examinees.model";
import { IExamCreateRequest, IExamEntity } from "./exam.model";

export const onAllExamGet = (): Promise<IExamEntity[]> =>
	rAxios.get("/admin/exam");
export const onExamCreate = (
	payload: IExamCreateRequest
): Promise<GenericMessageResponse> => rAxios.post("/admin/exam", payload);

// onExamActivate
export const onExamActivate = (
	examId: string
): Promise<GenericMessageResponse> => rAxios.put(`/admin/exam/${examId}/on`);

// onExamDeactivate
export const onExamDeactivate = (
	examId: string
): Promise<GenericMessageResponse> => rAxios.put(`/admin/exam/${examId}/off`);

// onExamDelete
export const onExamDelete = (examId: string): Promise<GenericMessageResponse> =>
	rAxios.delete(`/admin/exam/${examId}`);
