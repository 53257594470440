import { message } from "antd";
import { useAppDispatch, useAppSelector } from "store";
import { authActions } from "./auth.redux";
import {
	decodeToken,
	onExamineeLogin,
	onPasswordChange,
	onPasswordResetRequest,
} from "./auth.service";

export const useAuth = () => {
	const authStates = useAppSelector((state) => state.auth);

	const dispatch = useAppDispatch();

	const login = async (username: string, password: string) => {
		try {
			dispatch(authActions.loading());
			const resp = await onExamineeLogin(username, password);

			if (resp.user.role !== "ADMIN")
				throw new Error("You do not access to this dashboard.");

			dispatch(authActions.login(resp));
			localStorage.setItem("token", resp.token);

			message.success("Logged in successfully.");
		} catch (ex) {
			dispatch(authActions.failed(ex.message || "Something went wrong."));
			message.error(
				ex.response?.data?.message || "Log-in failed. Try again."
			);
		}
	};

	const logout = () => {
		localStorage.removeItem("token");
		dispatch(authActions.logout());
		message.success("You have been logged out.");
	};

	const quickLogin = async (token: string) => {
		try {
			const user = decodeToken(token);

			if (user.role !== "ADMIN")
				throw new Error("You do not access to this dashboard.");

			dispatch(
				authActions.login({
					token,
					user: user,
				})
			);
		} catch (ex) {
			message.error("Could not quick-login. Log in again.");
			localStorage.removeItem("token");
			logout();
		}
	};

	const passwordResetRequest = async (email: string) => {
		try {
			await onPasswordResetRequest(email);

			message.success("Password reset successful");
		} catch (ex) {
			message.error(
				ex.response?.data?.message ||
					"Failed to request password reset. Try again."
			);
			throw ex;
		}
	};

	const passwordReset = async (
		id: string,
		token: string,
		password: string
	) => {
		try {
			const resp = await onPasswordChange(id, token, password);

			const { message: _messsage = "" } = resp as any;

			message.success(
				_messsage ||
					"Password reset successful. Please check your email for reset link."
			);
		} catch (ex) {
			message.error(
				ex.response?.data?.message ||
					"Failed to request password reset. Try again."
			);
			throw ex;
		}
	};

	return {
		...authStates,
		login,
		quickLogin,
		logout,
		passwordReset,
		passwordResetRequest,
	};
};
