import React from "react";
import { Route, Switch } from "react-router-dom";

import { BackTop } from "antd";

import ExamView from "modules/Exams";
import ExamineeView from "modules/Examinees";
import QuestionView from "modules/Questions";
import ScoreView from "modules/Scores";
import LogView from "modules/Logs";

function DashboardPlayground() {
	return (
		<div className="playground">
			<Switch>
				<Route path="/exams" component={ExamView} />
				<Route path="/candidates" component={ExamineeView} />
				<Route path="/questions" component={QuestionView} />
				<Route path="/scores" component={ScoreView} />
				<Route path="/logs" component={LogView} />
			</Switch>
			<BackTop />
		</div>
	);
}

export default DashboardPlayground;
