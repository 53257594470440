import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./entities/auth/auth.redux";
import { examineeSlice } from "./entities/examinees/examinees.redux";
import { examSlice } from "./entities/exams/exam.redux";
import { questionSlice } from "./entities/questions/question.redux";

export const reducers = combineReducers({
	auth: authSlice.reducer,
	exams: examSlice.reducer,
	examinees: examineeSlice.reducer,
	questions: questionSlice.reducer,
});
