import { message } from "antd";
import { onLogGetForAll, onLogGetForSymbolNumber } from "./log.service";

export const useLogs = () => {
	const fetchLogForUser = async (symbolNumber: string) => {
		try {
			const resp = await onLogGetForSymbolNumber(symbolNumber);
			return resp;
		} catch (ex) {
			message.error(
				ex.response?.data?.message ||
					"Could not fetch log data of " + symbolNumber
			);
			throw ex;
		}
	};
	const fetchAllLog = async () => {
		try {
			const resp = await onLogGetForAll();
			return resp;
		} catch (ex) {
			message.error(
				ex.response?.data?.message || "Could not fetch all log data"
			);
		}
	};
	return { fetchLogForUser, fetchAllLog };
};
