import AdminPageHeader from "components/AdminPageHeader";
import SideDrawer from "components/SideDrawer";
import { useSideDrawer } from "hooks/useSideDrawer";
import React from "react";
import ExamCreateForm from "./ExamCreateForm";
import ExamList from "./ExamList";

function ExamView() {
	const { visible, drawerActions } = useSideDrawer();

	return (
		<div className="exam_view">
			<AdminPageHeader title="Exams" action={drawerActions.open} />
			<ExamList />
			<SideDrawer
				title="Add Exam Round"
				onClose={drawerActions.close}
				visible={visible}
			>
				<ExamCreateForm onFinish={drawerActions.close} />
			</SideDrawer>
		</div>
	);
}

export default ExamView;
