import React, { useState } from "react";
import { Button, Form, Input, Space } from "antd";
import { useQuestions } from "store/entities/questions/useQuestions";
import { IQuestionLongQuestionCreateRequest } from "store/entities/questions/question.model";
import TextArea from "antd/lib/input/TextArea";
import ImageUploadField from "components/ImageUploadField";

interface CreateLongQuestionProps {
	onFinish?: () => void;
}

function CreateLongQuestion({ onFinish }: CreateLongQuestionProps) {
	const { addLong } = useQuestions();
	const [form] = Form.useForm();
	const [image, setImage] = useState<File | null>(null);

	const _addLong = (values: any) => {
		let _values = values as IQuestionLongQuestionCreateRequest;
		_values.image = image;
		addLong(_values).then(() => {
			onFinish && onFinish();
		});
	};

	return (
		<div>
			<Form
				preserve={false}
				form={form}
				layout="vertical"
				onFinish={_addLong}
			>
				<Form.Item
					name="title"
					label="Question Title"
					required
					rules={[
						{
							required: true,
							message: "Please, enter question title.",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="subtext"
					label="Question Content"
					required
					rules={[
						{
							required: true,
							message: "Please, enter question content.",
						},
					]}
				>
					<TextArea />
				</Form.Item>

				<ImageUploadField file={image} onChange={setImage} />

				<Space
					direction="vertical"
					align="end"
					style={{ width: "100%", marginTop: 10 }}
				>
					<Button type="primary" htmlType="submit">
						Create
					</Button>
				</Space>
			</Form>
		</div>
	);
}

export default CreateLongQuestion;
