import React from "react";
import { Route, Switch } from "react-router-dom";
import { useAuth } from "store/entities/auth/useAuth";

// views
import LoginView from "views/LoginView/login.view";
import DashboardView from "views/DashboardView/dashboard.view";
import AuthoritiveView from "views/AuthoritiveView/authoritive.view";

function AppRoutes() {
	const { isLogged } = useAuth();

	return (
		<div className="routes">
			<Switch>
				<Route path="/forgot-password" component={AuthoritiveView} />
				{isLogged ? (
					<Route path="/" component={DashboardView} />
				) : (
					<Route path="/" component={LoginView} />
				)}
			</Switch>
		</div>
	);
}

export default AppRoutes;
