import rAxios from "utils/rAxios";
import { IScore, IScoreLongQuestion } from "./score.model";

export const onScoreAllGet = (examId: string): Promise<IScore[]> =>
	rAxios.get(`/admin/score/exam/${examId}`);

export const onScoreOneUserGet = (
	examId: string,
	userId: string
): Promise<IScore> =>
	rAxios.get(`/admin/score/exam/${examId}?userId=${userId}`);

export const onLongAnswerGet = (
	userId: string
): Promise<IScoreLongQuestion[]> =>
	rAxios.get(`/admin/lngqanswers/user/${userId}`);
