import {
	GenericMessageResponse,
	IExamineeCreateRequest,
	IExamineeUser,
} from "./examinees.model";
import rAxios from "utils/rAxios";

export const onAllExamineesGet = (): Promise<IExamineeUser[]> =>
	rAxios.get("/admin/users");

export const onExamineeUpdate = (
	from: string,
	to: string
): Promise<GenericMessageResponse> => rAxios.put(`admin/users/${from}/${to}`);

export const onExamineeAdd = (
	payload: IExamineeCreateRequest
): Promise<GenericMessageResponse> => rAxios.post("/admin/users", payload);

export const onCSVUpload = (file: File): Promise<GenericMessageResponse> => {
	const fd = new FormData();
	fd.append("file", file);
	return rAxios.post("admin/upload-csv", fd);
};
