import React from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useExaminees } from "store/entities/examinees/useExaminees";
import { getExamineeModel } from "./examinees.tablemodel";

function ExamineesList() {
	const { examinees, fetchAllExaminees, status } = useExaminees();

	useEffect(() => {
		if (!examinees.length) fetchAllExaminees();
		// eslint-disable-next-line
	}, []);

	return (
		<div className="table">
			<DataTable
				pointerOnHover
				highlightOnHover
				progressPending={status === "loading"}
				data={examinees}
				columns={getExamineeModel()}
			/>
		</div>
	);
}

export default ExamineesList;
