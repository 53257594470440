import { Tag } from "antd";
import type { TableColumn } from "react-data-table-component";
import { ILog } from "store/entities/logs/log.model";

export const getLogModel = (): TableColumn<ILog>[] => {
	return [
		{
			name: "Symbol Number",
			cell: (item) => <span>{item.user.symbolNumber}</span>,
		},
		{
			name: "Shift",
			cell: (item) => (
				<span>{item.user.examTag.title || <i>Not specified</i>}</span>
			),
		},
		{
			name: "Entries",
			cell: (item) => {
				const count = item.log.length;
				return (
					<span>
						<Tag
							color={
								count <= 32
									? "green"
									: count <= 42
									? "yellow"
									: "red"
							}
						>
							{count}
						</Tag>
					</span>
				);
			},
		},
		{
			name: "OS",
			cell: (item) => <span>{item.device.os}</span>,
			center: true,
		},
		{
			name: "Platform / Browser",
			cell: (item) => (
				<span>
					{item.device.platform} / {item.device.browser}
				</span>
			),
			right: true,
		},
	];
};
