import React from "react";
import LongQuestionTable from "./LongQuestion";
import MCQTable from "./MCQ";

import { Tabs } from "antd";

function QuestionLists() {
	return (
		<div className="table">
			<Tabs>
				<Tabs.TabPane tab="MCQs" key="mcq">
					<MCQTable />
				</Tabs.TabPane>
				<Tabs.TabPane tab="Long Questions" key="longquestions">
					<LongQuestionTable />
				</Tabs.TabPane>
			</Tabs>
		</div>
	);
}

export default QuestionLists;
