import { Skeleton } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { IScoreLongQuestion } from "store/entities/scores/score.model";
import { useScore } from "store/entities/scores/useScores";

interface LongAnswerReportProps {
	userId: string;
}

function LongAnswerReport({ userId }: LongAnswerReportProps) {
	const { fetchLongAnswerReport } = useScore();
	const [report, setReport] = useState<{
		status: "idle" | "loading" | "loaded" | "error";
		data: IScoreLongQuestion[];
		error?: "";
	}>({
		status: "idle",
		data: [],
		error: "",
	});

	useEffect(() => {
		setReport({
			status: "loading",
			data: [],
			error: "",
		});
		fetchLongAnswerReport(userId)
			.then((rep) => {
				setReport({
					status: "loaded",
					data: rep,
				});
			})
			.catch((ex) => {
				setReport({
					status: "error",
					data: [],
					error:
						ex.response?.data?.message ||
						"Could not load data at the moment",
				});
			});

		// eslint-disable-next-line
	}, [userId]);

	return (
		<div className="report">
			{report.status === "loading" ? <Skeleton /> : null}
			{report.status === "error" ? (
				<p>
					<i>{report.error}</i>
				</p>
			) : null}
			{report.status === "loaded" ? (
				!report.data.length ? (
					<p>No data available.</p>
				) : (
					report.data.map((item, i) => (
						<div key={i} className="answers">
							{item.answer.map((link, ii) => (
								<a target="_blank" rel="noreferrer" href={link}>
									{link}
								</a>
							))}
						</div>
					))
				)
			) : null}
		</div>
	);
}

export default LongAnswerReport;
