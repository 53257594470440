import React from "react";
import AdminPageHeader from "components/AdminPageHeader";
import LogList from "./LogList";

import "./logs.styles.scss";

function LogView() {
	return (
		<div className="log_view">
			<AdminPageHeader title="Logs" />
			<LogList />
		</div>
	);
}

export default LogView;
