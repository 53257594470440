import type { TableColumn } from "react-data-table-component";
import { IExamineeUser } from "store/entities/examinees/examinees.model";
import { IExamEntity } from "store/entities/exams/exam.model";
import { IScorePopulated } from "store/entities/scores/score.model";

export const getScoreModel = (
	examinees: IExamineeUser[],
	exams: IExamEntity[]
): TableColumn<IScorePopulated>[] => {
	return [
		{
			name: "Symbol Number",
			cell: (item) => (
				<span>
					{item.user?.symbolNumber || <i>Candidate not in list</i>}
				</span>
			),
		},
		{
			name: "Exam",
			cell: (item) => (
				<span>
					{exams.find((it) => it._id === item.exam)?.title || (
						<i>Exam not in the system.</i>
					)}
				</span>
			),
		},
		{
			name: "Total Score",
			selector: (item) => item.total,
		},
	];
};
