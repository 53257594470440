import { TableColumn } from "react-data-table-component";
import { IQuestionLongEntity } from "store/entities/questions/question.model";

export const getLongQuestionModel = (): TableColumn<IQuestionLongEntity>[] => [
	{
		name: "Question Title",
		cell: (item) => (
			<span>
				{item.title.length <= 50
					? item.title
					: item.title.substr(0, 50) + "..."}
			</span>
		),
	},
	{
		name: "Question Content",
		cell: (item) => (
			<span>
				{item.subtext &&
					(item.subtext.length <= 50
						? item.subtext
						: item.subtext.substr(0, 50) + "...")}
			</span>
		),
	},
	{
		name: "Supplement",
		cell: (item) => (
			<span>
				<img
					className="thumbnail"
					src={item.image}
					alt="thumbnail long question supplement"
				/>
			</span>
		),
	},
];
