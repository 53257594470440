import React, { useState } from "react";
// import TextArea from "antd/lib/input/TextArea";
import ImageUploadField from "components/ImageUploadField";
import { Button, Form, Input, Space, Select } from "antd";
import { useQuestions } from "store/entities/questions/useQuestions";
import { IQuestionMCQCCreateRequest } from "store/entities/questions/question.model";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

interface CreateMCQProps {
	onFinish?: () => void;
}

function CreateMCQ({ onFinish }: CreateMCQProps) {
	const { addMCQ } = useQuestions();
	const [mcqForm] = Form.useForm();
	const [image, setImage] = useState<File | null>(null);

	const [opt1Image, setOpt1Image] = useState<File | null>(null);
	const [opt2Image, setOpt2Image] = useState<File | null>(null);
	const [opt3Image, setOpt3Image] = useState<File | null>(null);
	const [opt4Image, setOpt4Image] = useState<File | null>(null);

	const [correctAns, setCorrentAns] = useState<number>(0);
	const [mcqTitle, setMcqTitle] = useState<string>("");

	const _addMcq = (values: any) => {
		let _values = values as IQuestionMCQCCreateRequest;

		console.log({ values });

		_values.image = image;
		_values.tag = values.tag;
		_values.title = mcqTitle;

		const {
			opt1String = "",
			opt2String = "",
			opt3String = "",
			opt4String = "",
		} = values;

		// add options here
		_values.opt1 = {
			text: opt1String,
			image: opt1Image,
		};
		_values.opt2 = {
			text: opt2String,
			image: opt2Image,
		};
		_values.opt3 = {
			text: opt3String,
			image: opt3Image,
		};
		_values.opt4 = {
			text: opt4String,
			image: opt4Image,
		};
		// options add ends here

		_values.correctAns = correctAns;

		addMCQ(_values as IQuestionMCQCCreateRequest).then(() => {
			onFinish && onFinish();
		});
	};

	return (
		<div>
			<Form
				preserve={false}
				form={mcqForm}
				layout="vertical"
				onFinish={_addMcq}
			>
				{/* <Form.Item
					name="title"
					label="Question"
					required
					rules={[
						{ required: true, message: "Please, enter question." },
					]}
				>
					<TextArea />
				</Form.Item> */}

				<ReactQuill
					theme="snow"
					value={mcqTitle}
					onChange={setMcqTitle}
				/>

				<ImageUploadField file={image} onChange={setImage} />

				<Form.Item name="tag" label="Tag" required>
					<Select>
						<Select.Option value="VERBAL">Verbal</Select.Option>
						<Select.Option value="QUANTITATIVE">
							Quantitative
						</Select.Option>
					</Select>
				</Form.Item>

				<div className="options">
					<h4>Choices</h4>

					{[
						{
							name: "opt1String",
							changeImage: setOpt1Image,
							image: opt1Image,
						},
						{
							name: "opt2String",
							changeImage: setOpt2Image,
							image: opt2Image,
						},
						{
							name: "opt3String",
							changeImage: setOpt3Image,
							image: opt3Image,
						},
						{
							name: "opt4String",
							changeImage: setOpt4Image,
							image: opt4Image,
						},
					].map((item, i) => (
						<div key={`option-${i}`} className="option">
							<div
								className={`correctAnswerCheckbox ${
									correctAns === i + 1 ? "active" : ""
								}`}
								onClick={() => setCorrentAns(i + 1)}
							>
								<span>Correct option</span>
							</div>

							<Form.Item
								name={item.name}
								label={`Option ${i + 1}`}
								required
								rules={[
									{
										required: true,
										message:
											"Please, enter option content.",
									},
								]}
							>
								<Input />
							</Form.Item>
							<ImageUploadField
								file={item.image}
								onChange={item.changeImage}
							/>
						</div>
					))}
				</div>

				<p>{mcqForm.getFieldError("correctAns")}</p>

				<Space
					direction="vertical"
					align="end"
					style={{ width: "100%", marginTop: 10 }}
				>
					<Button
						disabled={correctAns <= 0 || correctAns >= 5}
						type="primary"
						htmlType="submit"
					>
						Create
					</Button>
				</Space>
			</Form>
		</div>
	);
}

export default CreateMCQ;
