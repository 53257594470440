import React from "react";

import "antd/dist/antd.css";
import "styles/globalStyles.scss";

import { Layout } from "antd";
import AppRoutes from "routes/AppRoutes";
import { useEffect } from "react";
import { useAuth } from "store/entities/auth/useAuth";
// import AppHeader from "components/AppHeader";

function App() {
	const { quickLogin } = useAuth();

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token && token.length) quickLogin(token);
		// eslint-disable-next-line
	}, []);

	return (
		<Layout>
			<Layout>
				<AppRoutes />
			</Layout>
		</Layout>
	);
}

export default App;
