import { Form, Button, Input, Row, Col, Select, Space, Tabs } from "antd";
import CsvUploadField from "components/CsvUploadField";
import React, { useState } from "react";
import { IExamineeCreateRequest } from "store/entities/examinees/examinees.model";
import { useExaminees } from "store/entities/examinees/useExaminees";
import { useExams } from "store/entities/exams/useExams";

interface ExamineeCreateFormProps {
	onFinish?: () => void;
}

function ExamineeCreateForm({ onFinish }: ExamineeCreateFormProps) {
	const { addExaminee, uploadCSV } = useExaminees();
	const { exams } = useExams();

	const [file, setFile] = useState<File | null>(null);

	const [form] = Form.useForm();

	const _addExaminee = (values: any) => {
		addExaminee(values as IExamineeCreateRequest).then(() => {
			onFinish && onFinish();
		});
	};

	const _uploadCSV = () => {
		if (file)
			uploadCSV(file).then(() => {
				onFinish && onFinish();
			});
	};

	return (
		<div>
			<Tabs>
				<Tabs.TabPane tab="Individual" key="individual">
					<Form
						form={form}
						preserve={false}
						layout="vertical"
						onFinish={_addExaminee}
					>
						<Row gutter={10}>
							<Col span={8}>
								<Form.Item
									name="first_name"
									required
									label="First name"
									rules={[
										{
											required: true,
											message: "First name is required.",
										},
									]}
								>
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									name="middle_name"
									label="Middle name"
								>
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									name="surname"
									required
									label="Last name"
									rules={[
										{
											required: true,
											message: "Last name is required.",
										},
									]}
								>
									<Input />
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={10}>
							<Col span={12}>
								<Form.Item
									name="symbolNumber"
									required
									label="Symbol number"
									rules={[
										{
											required: true,
											message:
												"Symbol number is required.",
										},
									]}
								>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									name="citizenshipNumber"
									required
									label="Citizenship number"
									rules={[
										{
											required: true,
											message:
												"Citizenship number is required.",
										},
									]}
								>
									<Input />
								</Form.Item>
							</Col>
						</Row>

						<Form.Item
							name="examTag"
							required
							label="Exam shift"
							rules={[
								{
									required: true,
									message: "Please assign an exam round.",
								},
							]}
						>
							<Select>
								{exams.map((item) => (
									<Select.Option
										key={`exam-tag-${item._id}`}
										value={item._id}
									>
										{item.title}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Space
							direction="vertical"
							align="end"
							style={{ width: "100%" }}
						>
							<Button type="primary" htmlType="submit">
								Create
							</Button>
						</Space>
					</Form>
				</Tabs.TabPane>
				<Tabs.TabPane tab="Bulk" key="csvupload">
					<CsvUploadField file={file} onChange={setFile} />

					<Space
						direction="vertical"
						align="end"
						style={{ width: "100%", marginTop: 20 }}
					>
						<Button
							type="primary"
							disabled={!file}
							onClick={_uploadCSV}
						>
							Upload
						</Button>
					</Space>
				</Tabs.TabPane>
			</Tabs>
		</div>
	);
}

export default ExamineeCreateForm;
