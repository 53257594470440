import { Tabs } from "antd";
import React from "react";
import CreateLongQuestion from "./CreateLongQuestion";
import CreateMCQ from "./CreateMCQ";

interface QuestionCreateFormProps {
	onFinish?: () => void;
}

function QuestionCreateForm({ onFinish }: QuestionCreateFormProps) {
	return (
		<Tabs>
			<Tabs.TabPane tab="Add MCQ" key="create__mcq">
				<CreateMCQ onFinish={onFinish} />
			</Tabs.TabPane>
			<Tabs.TabPane tab="Long Question" key="create__long_question">
				<CreateLongQuestion onFinish={onFinish} />
			</Tabs.TabPane>
		</Tabs>
	);
}

export default QuestionCreateForm;
