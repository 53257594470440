import React from "react";
import { Button } from "antd";
import { FileAddOutlined } from "@ant-design/icons";

interface PageHeaderProps {
	title: string;
	action?: () => void;
}

function AdminPageHeader({ title, action }: PageHeaderProps) {
	return (
		<header className="pageHeader">
			<h2>
				<span>{title}</span>
				{action ? (
					<span>
						<Button
							type="primary"
							icon={<FileAddOutlined />}
							onClick={action}
						>
							Add
						</Button>
					</span>
				) : null}
			</h2>
		</header>
	);
}

export default AdminPageHeader;
