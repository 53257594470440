import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuth, IExamineeLoginResponse } from "./auth.model";

export interface IAuthRedux extends IAuth {
	status: "idle" | "loading" | "loaded" | "failed";
	error?: string;
}

const initState: IAuthRedux = {
	isLogged: false,
	token: "",
	user: null,
	status: "idle",
	error: "",
};

// define actions here
const login: CaseReducer<IAuthRedux, PayloadAction<IExamineeLoginResponse>> = (
	_,
	action
) => ({
	...action.payload,
	isLogged: true,
	status: "loaded",
	error: "",
});

const logout: CaseReducer<IAuthRedux> = (state) => {
	state.isLogged = false;
	state.error = "";
	state.token = "";
	state.user = null;
};

const loading: CaseReducer<IAuthRedux> = (state) => ({
	...state,
	status: "loading",
	error: "",
});

const failed: CaseReducer<IAuthRedux, PayloadAction<string>> = (
	state,
	action
) => {
	state.status = "failed";
	state.error = action.payload;
};
// action definition ends

export const authSlice = createSlice({
	name: "auth",
	initialState: initState,
	reducers: { login, logout, loading, failed },
});

export const authActions = authSlice.actions;
