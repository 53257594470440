import { Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { useExaminees } from "store/entities/examinees/useExaminees";
import { useExams } from "store/entities/exams/useExams";
import { IScorePopulated } from "store/entities/scores/score.model";
import { useScore } from "store/entities/scores/useScores";
import { getScoreModel } from "./scores.tablemodel";
import ScoreSingleUser from "./ScoreSingleUser";

function ScoreList() {
	const [scoreData, setScoreData] = useState<{
		status: "idle" | "loading" | "loaded" | "error";
		data: IScorePopulated[];
		filtered: IScorePopulated[];
	}>({ data: [], status: "idle", filtered: [] });
	const { fetchAllOfExam } = useScore();

	const [examId, setExamId] = useState<string>("");

	const { exams, fetchAllExams, status } = useExams();
	const {
		examinees,
		fetchAllExaminees,
		status: examineeStatus,
	} = useExaminees();

	useEffect(() => {
		fetchAllExams();
		fetchAllExaminees();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (examId) {
			setScoreData({ ...scoreData, status: "loading" });
			fetchAllOfExam(examId)
				.then((_data) => {
					const data = _data.map((item): IScorePopulated => {
						const _user = examinees.find(
							(it) => it._id === item.user
						);

						return { ...item, user: _user || null };
					});

					setScoreData({
						data,
						status: "loaded",
						filtered: [],
					});
				})
				.catch((ex) => {
					setScoreData({
						data: [],
						status: "error",
						filtered: [],
					});
				});
		} else {
			setScoreData({
				status: "idle",
				data: [],
				filtered: [],
			});
		}
		// eslint-disable-next-line
	}, [examId]);

	const [searchText, setSearchText] = useState<string>("");

	useEffect(() => {
		if (!searchText.length) setScoreData({ ...scoreData, filtered: [] });

		const _searchReg = new RegExp(searchText.toLowerCase(), "i");

		const _searched = scoreData.data.filter((item) => {
			const num = item.user?.symbolNumber.toLowerCase();
			return !!num && num.search(_searchReg) > -1;
		});

		setScoreData({
			...scoreData,
			filtered: _searched,
		});

		// eslint-disable-next-line
	}, [scoreData.data, searchText]);

	return (
		<div className="table">
			<div className="header" style={{ marginBottom: 20 }}>
				<Form layout="inline">
					<Form.Item label="Exam: " style={{ width: 200 }}>
						<Select
							allowClear
							placeholder="Choose a shift"
							loading={status === "loading"}
							onChange={(e) => setExamId(e as string)}
							onClear={() => {
								setExamId("");
							}}
						>
							{exams.map((item) => (
								<Select.Option key={item._id} value={item._id}>
									{item.title}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label="Search">
						<Input
							allowClear
							value={searchText}
							onChange={(e) => setSearchText(e.target.value)}
							placeholder="Symbol Number"
						/>
					</Form.Item>
					{searchText.length && !scoreData.filtered.length ? (
						<div
							className="info"
							style={{
								paddingTop: 5,
							}}
						>
							<small>Not found</small>
						</div>
					) : null}
				</Form>
			</div>
			<DataTable
				className="scoreTable"
				pointerOnHover
				highlightOnHover
				data={
					searchText.length || scoreData.filtered.length
						? scoreData.filtered
						: scoreData.data
				}
				columns={getScoreModel(examinees, exams)}
				progressPending={[
					scoreData.status,
					examineeStatus,
					status,
				].some((item) => item === "loading")}
				noDataComponent={
					<div style={{ padding: 24 }}>
						{examId
							? "There are no records to display."
							: "Choose an exam shift."}
					</div>
				}
				expandableRows
				expandOnRowClicked
				expandableRowsComponent={ScoreSingleUser}
			/>
		</div>
	);
}

export default ScoreList;
