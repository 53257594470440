import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	IQuestionLong,
	IQuestionLongEntity,
	IQuestionMCQ,
	IQuestionMCQEntity,
} from "./question.model";

export interface IQuestionRedux extends IQuestionMCQ, IQuestionLong {
	status: "idle" | "loading" | "loaded" | "failed";
	error?: string;
}

const initState: IQuestionRedux = {
	mcqs: [],
	long: [],
	status: "idle",
	error: "",
};

// define actions here
const addMCQs: CaseReducer<
	IQuestionRedux,
	PayloadAction<IQuestionMCQEntity[]>
> = (state, action) => {
	state.mcqs = action.payload;
	state.error = "";
	state.status = "loaded";
};
const addLongs: CaseReducer<
	IQuestionRedux,
	PayloadAction<IQuestionLongEntity[]>
> = (state, action) => {
	state.long = action.payload;
	state.error = "";
	state.status = "loaded";
};
const addOneMCQ: CaseReducer<
	IQuestionRedux,
	PayloadAction<IQuestionMCQEntity>
> = (state, action) => {
	state.mcqs = [action.payload, ...state.mcqs];
};
const addOneLong: CaseReducer<
	IQuestionRedux,
	PayloadAction<IQuestionLongEntity>
> = (state, action) => {
	state.long = [action.payload, ...state.long];
};
const deleteMCQ: CaseReducer<IQuestionRedux, PayloadAction<{ id: string }>> = (
	state,
	action
) => {
	state.mcqs = state.mcqs.filter((item) => item._id !== action.payload.id);
};
const deleteLong: CaseReducer<IQuestionRedux, PayloadAction<{ id: string }>> = (
	state,
	action
) => {
	state.long = state.long.filter((item) => item._id !== action.payload.id);
};

const empty: CaseReducer<IQuestionRedux> = (state) => {
	state.mcqs = [];
	state.long = [];
};
const loading: CaseReducer<IQuestionRedux> = (state) => ({
	...state,
	status: "loading",
	error: "",
});
const failed: CaseReducer<IQuestionRedux, PayloadAction<string>> = (
	state,
	action
) => {
	state.status = "failed";
	state.error = action.payload;
};

// action definitions ends

export const questionSlice = createSlice({
	name: "question",
	initialState: initState,
	reducers: {
		addMCQs,
		addLongs,
		addOneLong,
		addOneMCQ,
		deleteLong,
		deleteMCQ,
		loading,
		failed,
		empty,
	},
});

export const questionActions = questionSlice.actions;
