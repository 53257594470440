import { examineeActions } from "./examinees.redux";
import { message } from "antd";
import { useAppDispatch, useAppSelector } from "store";
import {
	onAllExamineesGet,
	onCSVUpload,
	onExamineeAdd,
} from "./examinees.service";
import { IExamineeCreateRequest } from "./examinees.model";

export const useExaminees = () => {
	const examineeState = useAppSelector((state) => state.examinees);

	const dispatch = useAppDispatch();

	const fetchAllExaminees = async () => {
		try {
			dispatch(examineeActions.loading());

			const resp = await onAllExamineesGet();

			dispatch(examineeActions.addExaminees(resp));
		} catch (ex) {
			dispatch(
				examineeActions.failed(
					ex.message || "Could not load examinees at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					"Failed to load examinees' data. Refresh."
			);
		}
	};

	const addExaminee = async (payload: IExamineeCreateRequest) => {
		try {
			await onExamineeAdd(payload);

			await fetchAllExaminees();
		} catch (ex) {
			dispatch(
				examineeActions.failed(
					ex.message || "Could not add examinee at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					"Failed to add examinee. Try again."
			);
		}
	};

	const uploadCSV = async (file: File) => {
		try {
			const resp = await onCSVUpload(file);

			message.success(resp.message);

			await fetchAllExaminees();
		} catch (ex) {
			dispatch(
				examineeActions.failed(
					ex.message ||
						"Could not upload examinee data at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					"Failed to upload bulk examinee data. Try again."
			);
		}
	};

	return { ...examineeState, fetchAllExaminees, addExaminee, uploadCSV };
};
