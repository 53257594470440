import React from "react";
import DashboardPlayground from "./widgets/DashboardPlayground";
import DashboardSidebar from "./widgets/DashboardSidebar";

import { Layout } from "antd";

import "./dashboard.styles.scss";

function DashboardView() {
	return (
		<div className="dashboard">
			<Layout>
				<Layout.Sider
					style={{
						overflow: "auto",
						height: "100vh",
						position: "fixed",
						left: 0,
					}}
					className="sidebar-wrappper"
				>
					<DashboardSidebar />
				</Layout.Sider>
				<Layout.Content style={{ marginLeft: "200px" }}>
					<DashboardPlayground />
				</Layout.Content>
			</Layout>
		</div>
	);
}

export default DashboardView;
