import { Button, Form, Input, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { IExamCreateRequest } from "store/entities/exams/exam.model";
import { useExams } from "store/entities/exams/useExams";

interface ExamCreateFormProps {
	onFinish?: () => void;
}

function ExamCreateForm({ onFinish }: ExamCreateFormProps) {
	const { addExam } = useExams();
	const [form] = useForm();

	const _addExam = (values: any) => {
		addExam(values as IExamCreateRequest).then(() => {
			// do something here
			onFinish && onFinish();
		});
	};

	return (
		<div>
			<Form
				preserve={false}
				form={form}
				layout="vertical"
				onFinish={_addExam}
			>
				<Form.Item
					name="title"
					required
					label="Exam Round"
					rules={[
						{ required: true, message: "Round name is required." },
					]}
				>
					<Input />
				</Form.Item>
				<Space
					direction="vertical"
					align="end"
					style={{ width: "100%" }}
				>
					<Button type="primary" htmlType="submit">
						Create
					</Button>
				</Space>
			</Form>
		</div>
	);
}

export default ExamCreateForm;
