import React, { FC } from "react";

import { ILog } from "store/entities/logs/log.model";
import { Descriptions } from "antd";
import DataTable from "react-data-table-component";
import { format } from "date-fns";

const LogSingleUser: FC<ILog> = (data) => {
	const _data = data as any;
	const dt: ILog = _data.data;

	return (
		<div className="logSheet">
			<div className="candidateInfo">
				<Descriptions
					column={5}
					title="User Logs"
					labelStyle={{ fontWeight: 500 }}
				>
					<Descriptions.Item label="DEFOCUS">
						{
							dt.log.filter((item) => item.log_type === "DEFOCUS")
								.length
						}
					</Descriptions.Item>
					<Descriptions.Item label="RESIZE">
						{
							dt.log.filter((item) => item.log_type === "RESIZE")
								.length
						}
					</Descriptions.Item>
					<Descriptions.Item label="MOUSELEAVE">
						{
							dt.log.filter(
								(item) => item.log_type === "MOUSELEAVE"
							).length
						}
					</Descriptions.Item>
					<Descriptions.Item label="SUBMIT">
						{
							dt.log.filter((item) => item.log_type === "SUBMIT")
								.length
						}
					</Descriptions.Item>
					<Descriptions.Item label="SKIP">
						{
							dt.log.filter((item) => item.log_type === "SKIP")
								.length
						}
					</Descriptions.Item>
				</Descriptions>
			</div>
			<div className="userLogList">
				<DataTable
					data={dt.log}
					columns={[
						{
							name: "S.N.",
							cell: (_, index) => <span>{index}</span>,
						},
						{
							name: "Log Type",
							cell: (log) => <span>{log.log_type}</span>,
						},
						{
							name: "Timestamp",
							cell: (log) => (
								<span>
									{format(
										new Date(log.created_at),
										"dd MMM, yyyy hh:mm:ss"
									)}
								</span>
							),
						},
					]}
				/>
			</div>
		</div>
	);
};

export default LogSingleUser;
