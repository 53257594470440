import rAxios from "utils/rAxios";
import { GenericMessageResponse } from "../examinees/examinees.model";
import {
	IQuestionLongEntity,
	IQuestionLongQuestionCreateRequest,
	IQuestionMCQCCreateRequest,
	IQuestionMCQEntity,
} from "./question.model";

export const onAllMCQGet = (): Promise<IQuestionMCQEntity[]> =>
	rAxios.get("/admin/mcqquestion");

export const onAllLongQuestionGet = (): Promise<IQuestionLongEntity[]> =>
	rAxios.get("/admin/longquestion");

export const onLongQuestionCreate = ({
	title,
	subtext,
	image,
}: IQuestionLongQuestionCreateRequest): Promise<GenericMessageResponse> => {
	const fd = new FormData();
	fd.append("qnsTitle", title);
	fd.append("subtext", subtext);
	if (image) fd.append("lqnsImage", image);
	return rAxios.post("/admin/longquestion", fd);
};

export const onMCQCreate = (
	payload: IQuestionMCQCCreateRequest
): Promise<GenericMessageResponse> => {
	const { title, image, opt1, opt2, opt3, opt4, correctAns, tag } = payload;

	const fd = new FormData();

	fd.append("qnsTitle", title);
	fd.append("correctAns", `${correctAns}`);
	fd.append("tag", tag);

	const _opts = [opt1, opt2, opt3, opt4];
	for (let i = 0; i < _opts.length; i++) {
		const _opt = _opts[i];
		fd.append(`opt${i + 1}String`, _opt.text);
		if (_opt.image) fd.append(`opt${i + 1}Image`, _opt.image);
	}

	if (image) fd.append("qnsImage", image);

	return rAxios.post("/admin/mcqquestion", fd);
};

// assign questions
export const onMCQAssignation = (examId: string) =>
	rAxios.post(`/admin/mcqquestion/users/${examId}`);
export const onLongQuestionAssignation = (examId: string) =>
	rAxios.post(`/admin/longquestion/users/${examId}`);
