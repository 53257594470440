import type { TableColumn } from "react-data-table-component";
import { IExamineeUser } from "store/entities/examinees/examinees.model";

export const getExamineeModel = (): TableColumn<IExamineeUser>[] => {
	return [
		{
			name: "Name",
			selector: (item) =>
				`${item.first_name}${
					item.middle_name ? " " + item.middle_name : ""
				} ${item.surname}`,
		},
		{
			name: "Symbol Number",
			selector: (item) => item.symbolNumber,
		},
		{
			name: "Citizenship Number",
			selector: (item) => item.citizenshipNumber,
		},
		{
			name: "Round",
			selector: (item) => item.examTag.title,
		},
	];
};
