import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, message, Tag } from "antd";
import React from "react";
import { IExamEntity } from "store/entities/exams/exam.model";
import { useExams } from "store/entities/exams/useExams";
import { useQuestions } from "store/entities/questions/useQuestions";

interface ExamUpdateFormProps {
	item: IExamEntity | null;
	onFinish?: () => void;
}

function ExamUpdateForm({ item, onFinish }: ExamUpdateFormProps) {
	const { assignMCQ, assignLongQuestions } = useQuestions();
	const { activateExam, deActivateExam, exams } = useExams();

	const _onQuestionAssign = () => {
		if (item) {
			Promise.all([
				assignMCQ(item._id),
				assignLongQuestions(item._id),
			]).then(() => {
				message.success("Questions assigned successfully!!!");
			});
		}
	};
	const _activateExam = () => {
		if (item)
			activateExam(item._id).then(() => {
				onFinish && onFinish();
			});
	};
	const _deActivateExam = () => {
		if (item)
			deActivateExam(item._id).then(() => {
				onFinish && onFinish();
			});
	};

	return (
		item && (
			<div>
				<h3>
					Status:{" "}
					<Tag
						icon={
							item.isActive ? (
								<Loading3QuartersOutlined spin />
							) : null
						}
						color={item.isActive ? "green" : "default"}
					>
						{item.isActive ? "Active" : "Inactive"}
					</Tag>
				</h3>

				{item.isActive ? (
					<>
						<div className="deactivate">
							<h4>
								In case an exam round need to be deactivated
								manually.
							</h4>
							<Button onClick={_deActivateExam} type="primary">
								Deactivate
							</Button>
						</div>
					</>
				) : (
					<>
						<div className="assign">
							<h4>
								Before activating an exam, be sure to assign
								questions to every one associated.
							</h4>
							<Button type="primary" onClick={_onQuestionAssign}>
								Assign Questions
							</Button>
						</div>
						<div className="activate">
							<h4>
								If every thing is ready, activate an exam to
								roll the questions out.
							</h4>
							<Button
								type="primary"
								onClick={_activateExam}
								disabled={exams.some((exm) => exm.isActive)}
							>
								Activate
							</Button>

							<p>
								{exams.some((exm) => exm.isActive)
									? "Other exam round are in session. Wait for them to finish before activating another round."
									: null}
							</p>
						</div>
					</>
				)}
			</div>
		)
	);
}

export default ExamUpdateForm;
