import { message } from "antd";
import { useAppDispatch, useAppSelector } from "store";
import { questionActions } from "./question.redux";
import {
	IQuestionLongQuestionCreateRequest,
	IQuestionMCQCCreateRequest,
} from "./question.model";
import {
	onAllLongQuestionGet,
	onAllMCQGet,
	onLongQuestionAssignation,
	onLongQuestionCreate,
	onMCQAssignation,
	onMCQCreate,
} from "./question.service";

export const useQuestions = () => {
	const questionStates = useAppSelector((state) => state.questions);
	const dispatch = useAppDispatch();

	const fetchAllMCQs = async () => {
		try {
			dispatch(questionActions.loading());
			const resp = await onAllMCQGet();
			dispatch(questionActions.addMCQs(resp));
		} catch (ex) {
			dispatch(
				questionActions.failed(
					ex.message || "Could not load MCQs at the moment."
				)
			);
			message.error(
				ex.response?.data?.message || "Failed to load MCQs. Refresh."
			);
		}
	};
	const fetchAllLongQuestions = async () => {
		try {
			dispatch(questionActions.loading());
			const resp = await onAllLongQuestionGet();
			dispatch(questionActions.addLongs(resp));
		} catch (ex) {
			dispatch(
				questionActions.failed(
					ex.message || "Could not load long questions at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					"Failed to load long questions. Refresh."
			);
		}
	};

	const addMCQ = async (payload: IQuestionMCQCCreateRequest) => {
		try {
			await onMCQCreate(payload);
			await fetchAllMCQs();
		} catch (ex) {
			dispatch(
				questionActions.failed(
					ex.message || "Could not add MCQ at the moment."
				)
			);
			message.error(
				ex.response?.data?.message || "Failed to add MCQ. Try again."
			);
		}
	};

	const addLong = async (payload: IQuestionLongQuestionCreateRequest) => {
		try {
			await onLongQuestionCreate(payload);
			await fetchAllLongQuestions();
		} catch (ex) {
			dispatch(
				questionActions.failed(
					ex.message || "Could not add long question at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					"Failed to add long question. Try again."
			);
		}
	};
	const assignMCQ = async (examId: string) => {
		try {
			await onMCQAssignation(examId);
		} catch (ex) {
			dispatch(
				questionActions.failed(
					ex.message || "Could not assign MCQs at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					"Failed to assign MCQs. Try again."
			);
			throw ex;
		}
	};
	const assignLongQuestions = async (examId: string) => {
		try {
			await onLongQuestionAssignation(examId);
		} catch (ex) {
			dispatch(
				questionActions.failed(
					ex.message ||
						"Could not assign long questions at the moment."
				)
			);
			message.error(
				ex.response?.data?.message ||
					"Failed to assign long questions. Try again."
			);
			throw ex;
		}
	};

	return {
		...questionStates,
		fetchAllMCQs,
		fetchAllLongQuestions,
		addMCQ,
		addLong,
		assignMCQ,
		assignLongQuestions,
	};
};
