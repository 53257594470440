import React from "react";
import { NavLink } from "react-router-dom";

import { BiBook } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { Avatar, Divider, Dropdown, Menu, Space } from "antd";
import { BsQuestionSquare } from "react-icons/bs";
import { ImBooks } from "react-icons/im";
import { IoLogoBuffer } from "react-icons/io";

import "./dashboardsidebar.styles.scss";

import adminAvatar from "assets/avatars/admin.png";
import { useAuth } from "store/entities/auth/useAuth";
import { MenuOutlined } from "@ant-design/icons";

function DashboardSidebar() {
	const { user, logout } = useAuth();

	return (
		<div className="sidebar">
			<Space direction="vertical" align="center" className="profile">
				<Avatar draggable={false} size={80} src={adminAvatar} />
				<div className="controls">
					<Dropdown
						placement="topCenter"
						trigger={["click"]}
						arrow
						overlay={
							<Menu theme="light">
								<Menu.Item key="0" onClick={logout}>
									<span>Log out</span>
								</Menu.Item>
							</Menu>
						}
					>
						<div className="username">
							{user?.email.split("@")[0]} <MenuOutlined />
						</div>
					</Dropdown>
				</div>
			</Space>
			<Divider />
			<ul className="list">
				<li>
					<NavLink to="/exams">
						<div className="link">
							<span>
								<BiBook size={18} />
							</span>
							Exams
						</div>
					</NavLink>
				</li>
				<li>
					<NavLink to="/candidates">
						<div className="link">
							<span>
								<FiUsers size={18} />
							</span>
							Candidates
						</div>
					</NavLink>
				</li>
				<li>
					<NavLink to="/questions">
						<div className="link">
							<span>
								<BsQuestionSquare size={18} />
							</span>
							Questions
						</div>
					</NavLink>
				</li>
				<li>
					<NavLink to="/scores">
						<div className="link">
							<span>
								<ImBooks size={18} />
							</span>
							Scores
						</div>
					</NavLink>
				</li>
				<li>
					<NavLink to="/logs">
						<div className="link">
							<span>
								<IoLogoBuffer size={20} />
							</span>
							Logs
						</div>
					</NavLink>
				</li>
			</ul>
		</div>
	);
}

export default DashboardSidebar;
