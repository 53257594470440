import React, { FC, useEffect, useState } from "react";
import { IScorePopulated } from "store/entities/scores/score.model";
import { Spreadsheet } from "react-spreadsheet";
import { useQuestions } from "store/entities/questions/useQuestions";
import { Descriptions, Tabs } from "antd";
import LongAnswerReport from "./LongAnswerReport";

export interface FlatScore {
	value: string;
}

const _headers = [
	...new Array(30)
		.fill(0)
		.map((_, i) => `Q.${(i + 1).toString().padStart(2, "0")}`),
];

const ScoreSingleUser: FC<IScorePopulated> = (data) => {
	const _data = data as any;
	const dt: IScorePopulated = _data.data;
	const [flats, setFlats] = useState<FlatScore[][]>([]);

	const { mcqs, fetchAllMCQs } = useQuestions();

	useEffect(() => {
		if (!mcqs.length) fetchAllMCQs();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let __answers = [];
		let __scores = [];

		for (let i = 0; i < 30; i++) {
			// __corrects.push({ value: mcqs.find(it => dt.paper[i]?.question || "")?. || "-" });
			__answers.push({ value: dt.paper[i]?.answer || "-" });
			__scores.push({ value: dt.paper[i]?.mark?.toString() || "-" });
		}

		setFlats([__answers, __scores]);
		// eslint-disable-next-line
	}, [data]);

	return (
		<div className="scoreSheet">
			<div className="candidateInfo">
				<Descriptions
					column={4}
					title="Score Report"
					labelStyle={{ fontWeight: 500 }}
				>
					<Descriptions.Item label="Name">
						{dt.user?.first_name} {dt.user?.middle_name}{" "}
						{dt.user?.surname}
					</Descriptions.Item>
					<Descriptions.Item label="Shift">
						{dt.user?.examTag.title}
					</Descriptions.Item>
					<Descriptions.Item label="Symbol Number">
						{dt.user?.symbolNumber}
					</Descriptions.Item>
					<Descriptions.Item label="Citizenship Number">
						{dt.user?.citizenshipNumber}
					</Descriptions.Item>
					<Descriptions.Item label="MCQ (Answered)">
						{dt.paper.length}
					</Descriptions.Item>
					<Descriptions.Item label="MCQ (Correct)">
						{dt.paper.filter((item) => item.mark === 1).length}
					</Descriptions.Item>
					<Descriptions.Item label="MCQ (Wrong)">
						{dt.paper.filter((item) => item.mark === -0.25).length}
					</Descriptions.Item>
					<Descriptions.Item label="MCQ (Skipped)">
						{dt.paper.filter((item) => item.mark === 0).length}
					</Descriptions.Item>
					<Descriptions.Item label="Score">
						{dt.total}
					</Descriptions.Item>
				</Descriptions>
			</div>
			<Tabs>
				<Tabs.TabPane key="mcq-scores" tab="MCQ Scores">
					<Spreadsheet
						columnLabels={_headers}
						rowLabels={["Candidate Chose", "Score"]}
						data={flats}
						onKeyDown={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
					/>
				</Tabs.TabPane>
				<Tabs.TabPane
					tab="Case Study Submissions"
					key="case-study-submission"
				>
					{/* <h3>Case Study Submissions</h3>
					<p>
						<i>Will be available soon.</i>
					</p> */}
					<LongAnswerReport userId={dt.user?._id || ""} />
				</Tabs.TabPane>
			</Tabs>
		</div>
	);
};

export default ScoreSingleUser;
